import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import Translate from '../../../localization/Localization';
import { Hierarchy, hierarchyColors } from '../../../shared/Map/AttributesColors';
import { ScoreTypes } from '../../../shared/models/ScoreTypes';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import styles2 from '../../../_variables2.scss';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import { RoadsSectionsSummaryModel } from '../models/RoadsSectionsSummaryModel';
import { ScoreColors } from '../models/ScoreColors';
import './IndicatorStatsStyles.scss';

interface IndicatorStatsComponentProps {
    mergedProject: MergedProjectVersion,
    summary: RoadsSectionsSummaryModel
}

interface PieChartModel {
    id: string,
    lengthInMeters: number,
    surface: number,
    color: string,
    percent: number
}

interface RoadConditionByDistrict {
    district: string,
    monitoringQualitySectionsLengthInMeters: number,
    generalMaintenanceQualitySectionsLengthInMeters: number,
    reinforcementQualitySectionsLengthInMeters: number,
    rehabilitationQualitySectionsLengthInMeters: number,
    localizedRepairQualitySectionsLengthInMeters: number
}

const CustomItemTooltipContent = (props): JSX.Element => {
    const { itemData, series, conditionOfTheRoadsInLinear } = props;
    let item = series.data[itemData.dataIndex];
    let value = conditionOfTheRoadsInLinear.find(x => x.id === item.id);

    return (
        <Box display="flex" flexDirection="column" bgcolor="white" width="100px" p="5px">
            <div>{`${MeasurementSystem.getLenghtInKilometersOrMiles(value.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`}</div>
            <div>{`${value.percent.toFixedLocalized(2)} %`}</div>
        </Box>
    );
};

export const IndicatorStatsComponent = (props: IndicatorStatsComponentProps): JSX.Element => {

    const [mainHierarchySectionsLengthInMeters, setMainHierarchySectionsLengthInMeters] = useState<number>(0);
    const [localHierarchySectionsLengthInMeters, setLocalHierarchySectionsLengthInMeters] = useState<number>(0);
    const [residentialHierarchySectionsLengthInMeters, setResidentialHierarchySectionsLengthInMeters] = useState<number>(0);
    const [conditionOfTheRoads, setConditionOfTheRoads] = useState<PieChartModel[]>([]);
    const [conditionOfTheRoadsByDistrict, setConditionOfTheRoadsByDistrict] = useState([]);

    useEffect(() => {
        let mainHierarchySectionsLengthInMeters = 0;
        let localHierarchySectionsLengthInMeters = 0;
        let residentialHierarchySectionsLengthInMeters = 0;

        props.mergedProject.roadsSections.forEach((section) => {
            let sectionLengthInMeters = section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
            switch (section.hierarchy) {
                case Hierarchy.main:
                    mainHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Hierarchy.local:
                    localHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Hierarchy.residential:
                    residentialHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    break;

                default:
                    break;
            }
        });

        setMainHierarchySectionsLengthInMeters(mainHierarchySectionsLengthInMeters);
        setLocalHierarchySectionsLengthInMeters(localHierarchySectionsLengthInMeters);
        setResidentialHierarchySectionsLengthInMeters(residentialHierarchySectionsLengthInMeters);

        setConditionOfTheRoads([
            {
                id: ScoreTypes.monitoring,
                color: styles2.monitoringQualityColor,
                lengthInMeters: props.summary.monitoringQualitySectionsLengthInMeters,
                surface: props.summary.monitoringQualitySectionsSurface,
                percent: props.summary.monitoringQualitySectionsPercent
            },
            {
                id: ScoreTypes.generalMaintenance,
                color: styles2.generalMaintenanceQualityColor,
                lengthInMeters: props.summary.generalMaintenanceQualitySectionsLengthInMeters,
                surface: props.summary.generalMaintenanceQualitySectionsSurface,
                percent: props.summary.generalMaintenanceQualitySectionsPercent
            },
            {
                id: ScoreTypes.reinforcement,
                color: styles2.reinforcementQualityColor,
                lengthInMeters: props.summary.reinforcementQualitySectionsLengthInMeters,
                surface: props.summary.reinforcementQualitySectionsSurface,
                percent: props.summary.reinforcementQualitySectionsPercent
            },
            {
                id: ScoreTypes.rehabilitation,
                color: styles2.rehabilitationQualityColor,
                lengthInMeters: props.summary.rehabilitationQualitySectionsLengthInMeters,
                surface: props.summary.rehabilitationQualitySectionsSurface,
                percent: props.summary.rehabilitationQualitySectionsPercent
            },
            {
                id: ScoreTypes.localizedRepair,
                color: styles2.localizedRepairQualityColor,
                lengthInMeters: props.summary.localizedRepairQualitySectionsLengthInMeters,
                surface: props.summary.localizedRepairQualitySectionsSurface,
                percent: props.summary.localizedRepairQualitySectionsPercent
            }
        ]);

        let sectionsByDistrictGroup = groupBy(Array.from(props.mergedProject.roadsSections), x => x[1].district);
        let conditionOfTheRoadsByDistrict: RoadConditionByDistrict[] = [];
        Object.entries(sectionsByDistrictGroup).forEach(([key, value]) => {
            if (key !== 'null') {
                let monitoringQualitySelectedSectionsSurface: number = 0;
                let localizedRepairQualitySelectedSectionsSurface = 0;
                let generalMaintenanceQualitySelectedSectionsSurface = 0;
                let reinforcementQualitySelectedSectionsSurface = 0;
                let rehabilitationQualitySelectedSectionsSurface = 0;

                value.forEach(([valueKey, section]) => {
                    let lengthInMeters = Math.round(section.lengthInMeters);
                    let surface = Math.round(lengthInMeters * section.widthInMeters);

                    switch (section.scoreColor) {
                        case ScoreColors.monitoring:
                            monitoringQualitySelectedSectionsSurface += surface;
                            break;

                        case ScoreColors.localizedRepair:
                            localizedRepairQualitySelectedSectionsSurface += surface;
                            break;

                        case ScoreColors.generalMaintenance:
                            generalMaintenanceQualitySelectedSectionsSurface += surface;
                            break;

                        case ScoreColors.reinforcement:
                            reinforcementQualitySelectedSectionsSurface += surface;
                            break;

                        case ScoreColors.rehabilitation:
                            rehabilitationQualitySelectedSectionsSurface += surface;
                            break;

                        default:
                            break;
                    }
                });

                conditionOfTheRoadsByDistrict.push({
                    district: key,
                    monitoringQualitySectionsLengthInMeters: monitoringQualitySelectedSectionsSurface,
                    generalMaintenanceQualitySectionsLengthInMeters: generalMaintenanceQualitySelectedSectionsSurface,
                    localizedRepairQualitySectionsLengthInMeters: localizedRepairQualitySelectedSectionsSurface,
                    rehabilitationQualitySectionsLengthInMeters: rehabilitationQualitySelectedSectionsSurface,
                    reinforcementQualitySectionsLengthInMeters: reinforcementQualitySelectedSectionsSurface
                });
            }
        });

        setConditionOfTheRoadsByDistrict(conditionOfTheRoadsByDistrict);
    }, [props.mergedProject, props.summary])

    console.log(conditionOfTheRoadsByDistrict);

    return (
        <Box className="indicator-stats">
            <Box display="flex" flexDirection="row" justifyContent="space-between" mb="10px">
                <Box display="flex" flexDirection="column" className="length-by-hierarchy-type">
                    <div className="title">{Translate.Resources.UI_RoadConditionIndicatorStats_LengthByHierarchyType}</div>
                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: [Hierarchy.main, Hierarchy.local, Hierarchy.residential],
                            label: Translate.Resources.UI_ActionsMenu_Attributes_Hierarchy,
                            tickLabelInterval: () => false
                        }]}
                        tooltip={{
                            trigger: 'none'
                        }}
                        legend={{
                            hidden: true
                        }}
                        yAxis={[{
                            label: MeasurementSystem.getSymbolOfKilometersOrMiles(),
                        }]}
                        series={[
                            {
                                data: [MeasurementSystem.getLenghtInKilometersOrMiles(mainHierarchySectionsLengthInMeters), 0, 0],
                                label: Translate.Resources.UI_Highways_Grid_Hierarchy_Main,
                                color: hierarchyColors[Hierarchy.main]
                            },
                            {
                                data: [0, MeasurementSystem.getLenghtInKilometersOrMiles(localHierarchySectionsLengthInMeters), 0],
                                label: Translate.Resources.UI_Highways_Grid_Hierarchy_Local,
                                color: hierarchyColors[Hierarchy.local]
                            },
                            {
                                data: [0, 0, MeasurementSystem.getLenghtInKilometersOrMiles(residentialHierarchySectionsLengthInMeters)],
                                label: Translate.Resources.UI_Highways_Grid_Hierarchy_Residential,
                                color: hierarchyColors[Hierarchy.residential]
                            }
                        ]}
                        width={400}
                        height={300}
                        sx={{
                            [`.${axisClasses.left} .${axisClasses.label}`]: {
                                transform: 'translate(28px, -40%)'
                            },
                            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                                transform: 'translate(150px, -5%)'
                            }
                        }}
                    />
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend main"></div>
                            <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Main}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend local"></div>
                            <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Local}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend residential"></div>
                            <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Residential}</div>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_ConditionOfTheRoadsInLinear} (${MeasurementSystem.getSymbolOfKilometersOrMiles()})`}</div>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <PieChart
                            series={[{
                                data: conditionOfTheRoads.map((item) => ({
                                    id: item.id,
                                    value: item.lengthInMeters,
                                    label: `${MeasurementSystem.getLenghtInKilometersOrMiles(item.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`
                                })),
                                arcLabel: "label"
                            }]}
                            sx={{
                                [`.${pieArcLabelClasses.root}`]: {
                                    fontSize: '9px'
                                }
                            }}
                            legend={{
                                hidden: true
                            }}
                            height={270}
                            width={270}
                            colors={conditionOfTheRoads.map((item) => item.color)}
                            tooltip={{ trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} conditionOfTheRoadsInLinear={conditionOfTheRoads} /> }}
                        />
                        <Box display="flex" flexDirection="column">
                            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend rehabilitation"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend reinforcement"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend general-maintenance"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend localized-repair"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend monitoring"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Monitoring}</div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_SurfaceRoadCondition} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <PieChart
                            series={[{
                                data: conditionOfTheRoads.map((item) => ({
                                    id: item.id,
                                    value: item.surface,
                                    label: `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(item.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfSurfaceUnit()}`
                                })),
                                arcLabel: "label"
                            }]}
                            sx={{
                                [`.${pieArcLabelClasses.root}`]: {
                                    fontSize: '9px'
                                }
                            }}
                            legend={{
                                hidden: true
                            }}
                            height={270}
                            width={270}
                            colors={conditionOfTheRoads.map((item) => item.color)}
                            tooltip={{ trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} conditionOfTheRoadsInLinear={conditionOfTheRoads} /> }}
                        />
                        <Box display="flex" flexDirection="column">
                            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend rehabilitation"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend reinforcement"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend general-maintenance"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend localized-repair"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend monitoring"></div>
                                <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Monitoring}</div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                conditionOfTheRoadsByDistrict.length > 0 &&
                <Box display="flex" flexDirection="column" className="surface-by-district-type">
                    <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_SurfaceRoadConditionBrokenDownByDistrict} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <Box className="surface-by-district-type-chart" >
                        <BarChart
                            xAxis={[{
                                scaleType: 'band',
                                dataKey: 'district',
                                label: 'Quartiers'
                            }]}
                            yAxis={[{
                                label: MeasurementSystem.getSymbolOfSurfaceUnit(),
                            }]}
                            series={[
                                { dataKey: 'monitoringQualitySectionsLengthInMeters', label: Translate.Resources.UI_ActionsMenu_Quality_Monitoring, stack: 'total' },
                                { dataKey: 'generalMaintenanceQualitySectionsLengthInMeters', label: Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance, stack: 'total' },
                                { dataKey: 'reinforcementQualitySectionsLengthInMeters', label: Translate.Resources.UI_ActionsMenu_Quality_Reinforcement, stack: 'total' },
                                { dataKey: 'rehabilitationQualitySectionsLengthInMeters', label: Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation, stack: 'total' },
                                { dataKey: 'localizedRepairQualitySectionsLengthInMeters', label: Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair, stack: 'total' },
                            ]}
                            dataset={conditionOfTheRoadsByDistrict as any}
                            width={800}
                            height={300}
                            colors={[styles2.monitoringQualityColor, styles2.generalMaintenanceQualityColor, styles2.reinforcementQualityColor, styles2.rehabilitationQualityColor, styles2.localizedRepairQualityColor]}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(28px, -40%)'
                                },
                                [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                                    transform: 'translate(360px, 0)'
                                }
                            }}
                            legend={{
                                hidden: true
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                        <Box display="flex" flexDirection="row" alignItems="center" ml="10px">
                            <div className="legend rehabilitation"></div>
                            <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend reinforcement"></div>
                            <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend general-maintenance"></div>
                            <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend localized-repair"></div>
                            <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className="legend monitoring"></div>
                            <div className="legend">{Translate.Resources.UI_ActionsMenu_Quality_Monitoring}</div>
                        </Box>
                    </Box>
                </Box>
            }
        </Box >
    );
}