import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompareIcon from '@mui/icons-material/Compare';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box, Button, ButtonGroup, Input, InputAdornment } from '@mui/material';
import React from "react";
import layersIcon from 'src/assets/icons/icon-layers.svg';
import mapButtonIcon from 'src/assets/icons/icon-map-button.svg';
import Translate from '../../../localization/Localization';
import { DisplayMode } from "../HighwaysView";
import './ActionBarComponent.scss';

interface ActionBarViewProps {
    displayMode: DisplayMode,
    isLayerMenuDisplayed: boolean,
    inputRef: React.RefObject<HTMLInputElement>,
    isRoadSectionDetailsOpened: boolean,
    updateDisplayMode: (displayMode: DisplayMode) => void,
    UpdateLayerMenuDisplay: () => void,
    handleSearchTextChanged: (value: string) => void
}

export const ActionBarComponent = (props: ActionBarViewProps) => {

    return (
        <div className="actions-bar-highways">
            <Box display="flex" flexDirection="row">
                <ButtonGroup variant="contained" aria-label="button group" className="button-group" disabled={props.isRoadSectionDetailsOpened}>
                    <Button className={`button map ${props.displayMode === DisplayMode.Map ? 'active' : ''} ${props.isRoadSectionDetailsOpened ? ' disabled' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.Map)}>
                        <img src={mapButtonIcon} alt="map button icon" />
                        <span className="label">{Translate.Resources.UI_ActionsMenu_Map}</span>
                    </Button>
                    <Button className={`button ${props.displayMode === DisplayMode.MapAndGrid ? 'active' : ''} ${props.isRoadSectionDetailsOpened ? ' disabled' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.MapAndGrid)}>
                        <CompareIcon className="view-list-icon" />
                        <span className="label">{Translate.Resources.UI_ActionsMenu_Map_Table}</span>
                    </Button>
                    <Button className={`button grid ${props.displayMode === DisplayMode.Grid ? 'active' : ''} ${props.isRoadSectionDetailsOpened ? ' disabled' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.Grid)}>
                        <ViewListIcon className="view-list-icon" />
                        <span className="label">{Translate.Resources.UI_ActionsMenu_Table}</span>
                    </Button>
                </ButtonGroup>
                <div className="search-input">
                    <Input disableUnderline className={`input-search-roads ${props.isRoadSectionDetailsOpened ? ' disabled' : ''}`} inputRef={props.inputRef} disabled={props.isRoadSectionDetailsOpened}
                        endAdornment={
                            <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }
                        placeholder={Translate.Resources.UI_ActionsBar_Search_Placeholder}
                        onChange={(event) => props.handleSearchTextChanged(event.target.value)}
                    />
                </div>
            </Box>
            <Button className={!props.isLayerMenuDisplayed ? "btn-secondary" : "btn-secondary is-selected"} onClick={() => props.UpdateLayerMenuDisplay()} disabled={props.isRoadSectionDetailsOpened}>
                <img src={layersIcon} alt="layers icon" />
                <span className="label">{Translate.Resources.UI_ActionsMenu_Layers}</span>
            </Button>
        </div>
    );
}