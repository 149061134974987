import { InteractionStatus } from "@azure/msal-browser";
import { WithMsalProps } from "@azure/msal-react";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import React, { Component } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MsalConfig } from './MsalConfig';
import { SettingsProvider } from './SettingsProvider';
import { MeasurementSystemType } from "./shared/models/MeasurementSystemType";
import { UserRoleIds } from "./shared/models/UserRoleIds";
import { UserModel } from "./shared/User/services/dataContracts/queryStack/UserModel";
import { UserApiClient } from './shared/User/services/UserApiClient';
import { MeasurementSystem } from "./utils/MeasurementSystem";
import FollowUpWorksView, { FollowUpWorksView as FollowUpWorksViewClass } from "./views/FollowUpWorks/FollowUpWorksView";
import GPSControlView from "./views/GPSControl/GPSControlView";
import HighwaysView, { HighwaysView as HighwaysViewClass } from "./views/Highways/HighwaysView";
import HomeView from "./views/Home/HomeView";
import { ProjectVersion } from "./views/Home/services/dataContracts/queryStack/ProjectVersion";
import { HomeApiClient } from "./views/Home/services/HomeApiClient";
import Layout from "./views/Layout";
import { NoAuscultationComponent } from "./views/NoAuscultation/NoAuscultationComponent";
import AreasManagementView, { AreasManagementView as AreasManagementViewClass } from "./views/Programmings/AreasManagement/AreasManagementView";
import ProgrammingsManagementView, { ProgrammingsManagementView as ProgrammingsManagementViewClass } from "./views/Programmings/ProgrammingsManagement/ProgrammingsManagementView";
import ProjectSettingsView, { ProjectSettingsView as ProjectSettingsViewClass } from "./views/ProjectSettings/ProjectSettingsView";
import RoadsConditionView, { RoadsConditionView as RoadsConditionViewClass } from "./views/RoadsCondition/RoadsConditionView";
import RoadsConditionView2, { RoadsConditionView2 as RoadsConditionView2Class } from "./views/RoadsCondition/RoadsConditionView2";
import ScenariosManagementView, { ScenariosManagementView as ScenariosManagementViewClass } from "./views/Scenarios/ScenariosManagement/ScenariosManagementView";
import ScenarioSectionsManagementView, { ScenarioSectionsManagementView as ScenarioSectionsManagementViewClass } from "./views/Scenarios/SectionsManagement/ScenarioSectionsManagement/ScenarioSectionsManagementView";
import ScenarioSectionsVisualisationView, { ScenarioSectionsVisualisationView as ScenarioSectionsVisualisationViewClass } from "./views/Scenarios/SectionsManagement/ScenarioSectionsVisualisation/ScenarioSectionsVisualisationView";
import UsersAdminView from "./views/UsersAdmin/UsersAdminView";

//TODO HGA CMA faire le ménage sur les resources (nettoyage + renommage)

interface AppState {
    hasAuscultation?: boolean,
    user: UserModel,
    allProjectsVersions: ProjectVersion[]
}

export class App extends Component<WithMsalProps, AppState> {
    _isMounted: boolean = false;
    _googleAnalyticsTrackingCode: string = null;
    currentView: React.RefObject<RoadsConditionViewClass
        | RoadsConditionView2Class
        | HighwaysViewClass
        | ScenariosManagementViewClass
        | ScenarioSectionsManagementViewClass
        | ScenarioSectionsVisualisationViewClass
        | ProgrammingsManagementViewClass
        | AreasManagementViewClass
        | ProjectSettingsViewClass
        | FollowUpWorksViewClass>;

    constructor(props: WithMsalProps) {
        super(props);

        MeasurementSystem.Init();

        this.currentView = React.createRef();

        this.state = {
            hasAuscultation: null,
            user: null,
            allProjectsVersions: []
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.ensureUserAuthentication();

        if (SettingsProvider.IsReady()) {
            this.getUserProfileAndAuscultations();
        }
    }

    componentDidUpdate() {
        this.ensureUserAuthentication();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ensureUserAuthentication() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect({ scopes: MsalConfig.GetLoginRequestScopes() });
        }
    }

    getMatomoInstance = (matomoUserId: string) => {
        if (SettingsProvider.IsReady()) {
            const settings = SettingsProvider.Get();
            if (settings.matomo && settings.matomo.urlBase && settings.matomo.siteId !== 0) {
                return createInstance({
                    urlBase: settings.matomo?.urlBase,
                    siteId: settings.matomo?.siteId,
                    userId: matomoUserId
                });
            }
            return null;
        }
        return null;
    }

    getUserProfileAndAuscultations = (): void => {
        Promise.all(
            [
                UserApiClient.GetUserProfile(),
                HomeApiClient.GetAllProjectsVersions()
            ])
            .then((res) => {
                if (this._isMounted) {
                    var userProfileData = res[0].data;
                    if (userProfileData) {
                        var user = userProfileData.isEnabled ? userProfileData : null
                        this.setState({
                            hasAuscultation: true,
                            user: user,
                            allProjectsVersions: res[1].data
                        });
                    }
                    else {
                        this.setState({ hasAuscultation: false });
                    }
                }
            });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        MeasurementSystem.Set(measurementSystemType);

        if (!this.currentView.current)
            return;

        let changeHandler = this.currentView.current.handleMeasurementSystemTypeChanged;
        if (changeHandler) {
            changeHandler(measurementSystemType);
        }
    }

    render() {
        const { hasAuscultation, user, allProjectsVersions } = this.state;
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const settingsReady = SettingsProvider.IsReady();
        const userRole = user?.role;
        const userPermissions = user?.permissions;
        if (isAuthenticated && settingsReady) {
            if (user) {
                return (
                    <MatomoProvider value={this.getMatomoInstance(user.matomoUserId)}>
                        <ToastContainer className="generic-toast" />
                        <Layout user={user} projects={allProjectsVersions} handleMeasurementSystemTypeChanged={this.handleMeasurementSystemTypeChanged} >
                            <Routes>
                                <Route path='/' element={hasAuscultation === true ? <HomeView /> :
                                    hasAuscultation === false ? <NoAuscultationComponent /> : <></>}
                                />
                                <Route path='/Highways' Component={() => <HighwaysView ref={this.currentView} userPermissions={userPermissions} />} />
                                <Route path='/RoadsCondition' Component={() => <RoadsConditionView ref={this.currentView} role={userRole} />} />
                                <Route path='/RoadsCondition_POC' Component={() => <RoadsConditionView2 ref={this.currentView} role={userRole} />} />
                                <Route path='/ScenariosManagement' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <ScenariosManagementView /> : <Navigate to="/" />} />
                                <Route path='/ScenarioSectionsManagement' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <ScenarioSectionsManagementView ref={this.currentView} role={userRole} /> : <Navigate to="/" />} />
                                <Route path='/ScenarioSectionsVisualisation' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <ScenarioSectionsVisualisationView ref={this.currentView} role={userRole} /> : <Navigate to="/" />} />
                                <Route path='/ProgrammingsManagement' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <ProgrammingsManagementView ref={this.currentView} /> : <Navigate to="/" />} />
                                <Route path='/ProgrammingAreasManagement' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <AreasManagementView ref={this.currentView} role={userRole} /> : <Navigate to="/" />} />
                                <Route path='/FollowUpWorks' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.agency || userRole === UserRoleIds.customerPlus || userRole === UserRoleIds.customer || userRole === UserRoleIds.viewer ? <FollowUpWorksView ref={this.currentView} role={userRole} /> : <Navigate to="/" />} />
                                <Route path='/ProjectSettings' Component={() => userRole === UserRoleIds.administrator || userRole === UserRoleIds.agency || userRole === UserRoleIds.agencyPlus || userRole === UserRoleIds.customerPlus ? <ProjectSettingsView ref={this.currentView} userPermissions={userPermissions} /> : <Navigate to="/" />} />
                                <Route path='/UsersAdmin' Component={() => userRole === UserRoleIds.administrator ? <UsersAdminView /> :
                                    userRole !== UserRoleIds.administrator ? <Navigate to="/" /> : <></>}
                                />
                                <Route path='/GPSControl' Component={() => userRole === UserRoleIds.administrator ? <GPSControlView /> :
                                    userRole !== UserRoleIds.administrator ? <Navigate to="/" /> : <></>}
                                />
                            </Routes>
                        </Layout>
                    </MatomoProvider>
                );
            }
            if (!user && hasAuscultation !== null) {
                return (
                    <Routes>
                        <Route path='/' element={<NoAuscultationComponent />} />
                    </Routes>
                )
            }
        }
        return (
            <></>
        );
    }
}
